import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import CookieBar from "../components/cookiebar";
import Footer from "../components/footer";
import SectionWhite from "../components/sectionwhite";
import ButtonRed from "../components/buttonred";
import SectionBG from "../components/sectionbg";
import HeroGirl from "../images/littlelikeme-hero.png";
import ValuesBG from "../images/values-bg.jpg";
import Video from "../components/video";
import DownloadLinks from "../components/download-links";
import AllFeatures from "../components/allfeatures";

const HomePage = () => {
  return (
    <div>
      <SEO />
      <CookieBar />
      <SectionWhite>
        <div className="md:w-3/5 text-center md:text-left md:float-left">
          <div>
            <StaticImage
              src="../images/littlelikeme-logo.png"
              alt="littlelike.me ™"
              height={100}
              placeholder="none"
            />
          </div>

          <h2 className="text-2xl lg:text-3xl my-4 font-bold">
            A New People Sim Game!
          </h2>
          <p className="leading-relaxed mb-4">
            Create a character that's a "little like" yourself, and help them
            live a simulated life in a world populated by characters created by
            other players!
          </p>
          <p class="leading-relaxed mb-4">
            Want to know more? Read the FAQ. Want to
            help playtest new releases? Sign up to get Development News.
          </p>
          <div class="flex flex-wrap align-center justify-center lg:justify-start gap-4">
            <ButtonRed buttonText="FAQ" buttonLink="/faq" />
            <ButtonRed
              buttonText="SIGN UP FOR DEVELOPMENT NEWS"
              buttonLink="/news/signup"
            />
          </div>

          {/* <p className="leading-relaxed mb-4">
            You can play littlelike.me{" "}
            <span class="font-bold italic">right now!</span>
          </p> */}
          {/* <DownloadLinks /> */}
          <div class="mt-2"></div>
        </div>
        <div className="relative w-auto md:w-2/5 top-4 md:-top-24 lg:-top-36  md:float-right">
          <img className="m-auto max-h-96 md:max-h-tall" src={HeroGirl} />
        </div>
      </SectionWhite>
      <SectionBG bgimage={ValuesBG}>
        <div className="clear-both" />
        <div className="flex flex-wrap md:flex-nowrap gap-6 justify-center content-center text-center font-medium">
          <div className="">
            <StaticImage
              className="mb-4 w-1/5 md:w-1/2"
              placeholder="none"
              src="../images/feature-dressup.png"
            />
            <h2 className="text-2xl mb-2 font-bold">Dress Up</h2>
            <p className="">
              Choose from a wide variety of outfits and styles to make a
              character a Little Like yourself.
            </p>
          </div>
          <div className="">
            <StaticImage
              className="mb-4 w-1/5 md:w-1/2"
              placeholder="none"
              src="../images/feature-decorate.png"
            />
            <h2 className="text-2xl mb-2 font-bold">Decorate</h2>
            <p>
              Decorate lots of your own Apartments before you unlock a Mansion
              of your own.
            </p>
          </div>
          <div className="">
            <StaticImage
              className="mb-4 w-1/5 md:w-1/2"
              placeholder="none"
              src="../images/feature-goout.png"
            />
            <h2 className="text-2xl mb-2 font-bold">Go Out</h2>
            <p>
              Go out and socialize with characters created by other players.
            </p>
          </div>
        </div>
      </SectionBG>
      <div className="m-auto flex justify-center w-full lg:w-3/4 mt-10">
        <Video videoSrcURL="https://www.youtube.com/embed/aMtdKPEyehw" />
      </div>
      <div class="container mx-auto px-4 py-20 max-w-xl">
        <p class="leading-relaxed mb-4 text-center">
          We're continually developing new features and content for the game.
          Want to learn what's recently changed? Need help? Head to the Support
          page.
        </p>
        <div class="flex align-center justify-center gap-4 mb-8">
          <ButtonRed buttonText="Support" buttonLink="/support" />
        </div>
        {/* <p class="leading-relaxed mb-4 text-center">
          Want to know more about the current release? Read the FAQ. Would you
          like to get news about upcoming features? Want to help playtest new
          releases? Sign up to get Development News.
        </p>
        <div class="flex flex-wrap align-center justify-center gap-4">
          <ButtonRed buttonText="FAQ" buttonLink="/faq" />
          <ButtonRed
            buttonText="SIGN UP FOR DEVELOPMENT NEWS"
            buttonLink="/news/signup"
          />
        </div> */}
      </div>

      <div className="w-auto lg:w-4/5 mx-auto px-6 md:px-14 text-center text-gray-900">
        <div class="flex flex-wrap align-center justify-center gap-4">
          {/* <DownloadLinks /> */}
        </div>
        <AllFeatures />
        <div class="flex flex-wrap align-center justify-center gap-4">
          {/* <DownloadLinks /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
